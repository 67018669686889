/* This example requires Tailwind CSS v2.0+ */
const people = [
    {
        name: 'Frontend Engineer',
    },
    {
        name: 'Dir. of Engineering',
    },
    {
        name: 'Data Scientist',
    },
    {
        name: 'UX Designer',
    },
    {
        name: 'Backend Engineer',
    },
    {
        name: 'Fullstack Engineer',
    },
    {
        name: 'QA Engineer',
    },
    {
        name: 'Product Manager',
    },
    {
        name: 'DevOps Engineer',
    },
    {
        name: 'Customer Experience',
    },
]


const Positions = () => {
    return (
        <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32 " id="positions">
            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                <div>
                    <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Technical Experts</h2>
                    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                        Most wanted positions
                    </p>
                    <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500 mb-4">
                        We provide technological profiles for startups and medium-sized companies.
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-5 pb-36">
                    {people.map((person) => (
                        <div key={person.name} className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <div className="flex-1 min-w-0">
                                <a href="#services" className="focus:outline-none">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <p className="text-sm font-medium text-gray-900">{person.name}</p>
                                    <p className="text-sm text-gray-500 truncate">{person.role}</p>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}

export default Positions;