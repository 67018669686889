import Header from './Header'
import Footer from './Footer'

import Hero from './Hero'
import Positions from './Positions'
import Technologies from './Technologies'
//import Testimonial from './Testimonial'
//import Blog from './Blog'
import Contact from './Contact'
import Why from './Why'
import HRSupport from './HRSupport'

export default function Landing() {
    return (
        <div className="bg-white flex flex-col h-screen">
            <div className="">
                <Header />
                <div className='overflow-y'>
                    {/* Secciones */}
                    <Hero />
                    <Positions />
                    <Why />
                    <Technologies />
                    <HRSupport />
                    {/* <Testimonial /> */}

                    <Contact />
                </div>
                <Footer />
            </div>
        </div>
    )
}
