import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useEffect } from "react";
import { useNavigate } from 'react-router'
import Landing from './landing/Landing';
import WhatsApp from './whatsapp/Whatsapp'

const contextClass = {
  success: "bg-blue-500",
  error: "bg-red-500",
};


function App() {
  /*
    const dispatch = useDispatch()
  
    const token = useSelector(state => state.auth.token);
  
    useEffect(() => {
      const tokenString = localStorage.getItem('token');
  
      if (!tokenString) {
        dispatch(fetchLogin())
      } else {
        if (token !== tokenString) {
          dispatch(fetchRefresh())
        }
      }
    }, [token, dispatch]);
  
  */

  const RedirectToHome = () => {
    const navigate = useNavigate()
    useEffect(() => {
      navigate('/');
    });
    return (<> </>)
  }

  return (<>
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<RedirectToHome />} />
      </Routes>
    </Router>

    <ToastContainer toastClassName={
      ({ type }) => contextClass[type || "default"] + " font-sans text-sm my-4 mx-6 relative flex p-2 min-h-15 justify-between overflow-hidden text-center"}
      position="top-center" autoClose={2000} hideProgressBar={true} closeOnClick rtl={false} limit={1} icon={false} theme="colored" />

    <WhatsApp />
  </>
  )
}

export default App;