import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { useState } from 'react';


export default function Contact() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleClick = (e) => {
        e.preventDefault();

        const url = 'https://wa.me/17544656343?text=' + encodeURIComponent('*Web Page Contact*\n'
            + 'Name: ' + name + '\n'
            + 'Email: ' + email + '\n'
            + 'Phone: ' + phone + '\n\n'
            + message + '\n');

        window.open(url);

        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
    }

    return (
        <div className="relative bg-white" id="contact">
            <div className="absolute inset-0">
                <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
            </div>
            <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                    <div className="max-w-lg mx-auto">
                        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Get in touch</h2>
                        <p className="mt-3 text-lg leading-6 text-gray-500">
                            tell us your need and we will give you a clear budget of how we can collaborate with your challenges
                        </p>
                        <dl className="mt-8 text-base text-gray-500">
                            <div className="mt-6">
                                <dt className="sr-only">Phone number</dt>
                                <dd className="flex">
                                    <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <span className="ml-3"><a href="https://wa.me/17544656343">+1 (754) 465-6343</a></span>
                                </dd>
                            </div>
                            <div className="mt-3">
                                <dt className="sr-only">Email</dt>
                                <dd className="flex">
                                    <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <span className="ml-3">info@axo.tech</span>
                                </dd>
                            </div>
                        </dl>
                        <p className="mt-6 text-base text-gray-500">
                            Looking for careers?{' '}
                            <a href="/" className="font-medium text-gray-700 underline">
                                View all job openings
                            </a>
                            .
                        </p>
                    </div>
                </div>
                <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                    <div className="max-w-lg mx-auto lg:max-w-none">
                        <form action="#" method="POST" className="grid grid-cols-1 gap-y-6">
                            <div>
                                <label htmlFor="full-name" className="sr-only">
                                    Full name
                                </label>
                                <input value={name} onChange={(value) => setName(value.target.value)}
                                    type="text" autoComplete="name"
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    placeholder="Full name"
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="sr-only">
                                    Email
                                </label>
                                <input value={email} onChange={(value) => setEmail(value.target.value)}
                                    id="email" name="email" type="email" autoComplete="email"
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    placeholder="Email"
                                />
                            </div>
                            <div>
                                <label htmlFor="phone" className="sr-only">
                                    Phone
                                </label>
                                <input value={phone} onChange={(value) => setPhone(value.target.value)}
                                    type="text" name="phone" id="phone" autoComplete="tel"
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    placeholder="Phone"
                                />
                            </div>
                            <div>
                                <label htmlFor="message" className="sr-only">
                                    Message
                                </label>
                                <textarea value={message} onChange={(value) => setMessage(value.target.value)}
                                    id="message" name="message" rows={4}
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                    placeholder="Message" />
                            </div>
                            <div>
                                <button type="submit" onClick={handleClick}
                                    className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
