

const HRSupport = () => {
    return (
        <div className="relative bg-gray-900" id="hrsupport">
            <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-0 lg:py-32">
                <div className="md:ml-auto md:w-auto md:pl-10">
                    <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                        Remote Talent
                    </h2>
                    <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">HR Support</p>
                    <p className="mt-3 text-lg text-3xl text-gray-300 text-justify">
                        From onboarding to offboarding, our international HR solutions will give you all the help you need to hire, pay, and manage international staff effectively.
                    </p>
                    <br />

                    <p className="mt-3 text-lg text-gray-300 text-justify">
                        Human Resource (HR) support can cover a wide range of services, but typically it involves outsourcing key HR functions like onboarding, compliance, payroll, and benefits to an external party. HR support may also include consultancy and practical advice.
                    </p>

                    <p className="mt-3 text-lg text-gray-300 text-justify">
                        HR outsourcing solutions are particularly popular for start-ups and SMBs who are yet to establish their own full-fledged HR and People teams. External HR support can help save time, money, and resources while ensuring the business remains HR compliant.
                    </p>

                    <p className="mt-3 text-lg text-gray-300 text-justify">
                        Many global businesses also opt for HR support because complying with multiple labor laws is very complicated. Without support, you must juggle numerous payroll accounts, benefits packages, and onboarding processes. It’s often much simpler and more cost-effective to partner with a global HR solutions provider like Omnipresent to carry out this work for you.
                    </p>
                </div>
            </div>
        </div>)
}

export default HRSupport;