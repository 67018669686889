//import { ExternalLinkIcon } from '@heroicons/react/solid'


const Why = () => {
    return (
        <div className="relative bg-gray-900" id="why">
            <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                <img
                    className="w-full h-full object-cover"
                    src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
                    alt=""
                />
                <div
                    aria-hidden="true"
                    className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
                />
            </div>
            <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
                <div className="md:ml-auto md:w-1/2 md:pl-10">
                    <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                        WORLD-CLASS Digital Nomad TALENT
                    </h2>
                    <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">WHY HIRE REMOTE?</p>
                    <p className="mt-3 text-lg text-gray-300 text-justify">
                        Latin America has some of the best developers in the world and they are recognized for the quality and dedication of their IT professionals.                    </p>
                    <p className="mt-3 text-lg text-gray-300 text-justify">
                        In addition to competitive rates and candidate quality, recruiting abroad gives your company the opportunity to find the best professionals in the world without geographic restrictions or relocation expenses.                    </p>
                    <p className="mt-3 text-lg text-gray-300 text-justify">
                        We are outsourcing experts, hiring validated senior remote engineers with strong technical and communication skills at unbeatable prices, ready to work in your time zone.
                    </p>
                    <p className="mt-3 text-lg text-gray-300 text-justify">
                        We have the best <b>Digital Nomad</b> in Argentina, Chile and Colombia.
                    </p>
                    { /* 
                    <div className="mt-8">
                        <div className="inline-flex rounded-md shadow">
                            <a href="/"
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                                Visit the candidates center
                                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                    */}
                </div>
            </div>
        </div>)
}

export default Why;