

const features = [
    {
        name: 'VueJs',
        description: 'Vue.js combines an easier learning curve with a versatile library that can be used to create stunningly responsible user interfaces.',
        image: 'images/vuejs.png',
    },
    {
        name: 'React.js',
        description: 'React.js is a front-end JavaScript framework used primarily to create user interfaces for applications.',
        image: 'images/react.png',
    },
    {
        name: 'Core Net',
        description: 'ASP.NET Core is the open-source version of ASP.NET, that runs on macOS, Linux, and Windows.',
        image: 'images/Microsoftnet.png',
    },
    {
        name: 'Node js',
        description: 'Node.js forms the basis for websites like Twitter and Shopify.',
        image: 'images/node.png',
    },
]

const Technologies = () => {
    return (
        <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="technologies">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Technologies</h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    Top-notch Cutting Edge Technology
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    We use the latest technologies in a very challenging world. We accompany the development of new technological paradigms,
                    evaluating and using the tools that best fit the requirements.
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
                        {features.map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                                            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                                                <img src={feature.image} alt='img' className='h-16' />
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                                        <p className="mt-5 text-base text-gray-500 text-justify">{feature.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Technologies;