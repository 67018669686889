import { ChevronRightIcon } from '@heroicons/react/solid'

const Hero = () => {
    return (
        <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-0 lg:pb-14 lg:overflow-hidden" id="hero">
            <div className="mx-auto max-w-7xl lg:px-0">
                <div className="lg:grid lg:grid-cols-2 lg:gap-0">
                    <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                        <div className="lg:py-12">
                            <a href="/" className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                                <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-teal-500 to-cyan-600 rounded-full">
                                    We're hiring
                                </span>
                                <span className="ml-4 text-sm">Remote Engineers in Latam</span>
                                <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                            </a>
                            <div className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl">
                                <h1 className="block">Remote Engineers in Latam</h1>
                                <span className="pb-3 tracking-normal block bg-clip-text text-xl text-transparent bg-gradient-to-r from-teal-200 to-cyan-400 sm:pb-5">
                                    We create and manage your Remote Staff from Latam to US
                                </span>
                            </div>
                            <p className="text-justify text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl lg:pb-4">
                                We select the best candidates in Latam, manage their hiring as a contractor and make them a memorable experience like your employees.                            </p>
                            <p className="text-justify text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl lg:pb-4">
                                We are tech experts recruiting, hiring and supporting your Remotely Tech Team.
                            </p>
                            <p className="text-justify text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl lg:pb-4">
                                Because we love technology and understand the technical mindset, we want to help you bring the best Latin American candidates to your US company.
                            </p>

                            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl lg:pt-2 lg:text-3xl tracking-wide">
                                MAINTAIN COMPLIANCE
                            </p>
                            <p className="text-justify text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl lg:pb-4">
                                While compliance in international employment is challenging and can never be guaranteed, we're there to support you every step of the way.
                            </p>
                        </div>
                    </div>
                    <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                            <img src="images/computers.svg" className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-2xl" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;